<template>
    <div>
        <MenuItem data-toggle="modal" data-target="#showAffiliate">
        Join Our Affiliate Program
        <img style="width: 30px;" src="/img/fire.png"/>
        </MenuItem>
        <div ref="affiliateModal" class="modal affiliate-modal fade" id="showAffiliate" tabindex="-1"
            aria-labelledby="showAffiliate" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-body">
                        <div class="wallet-modal">
                            <div class="md-title">
                                <h4 class="text-light">
                                    Be An Affiliate
                                </h4>
                            </div>
                            <section class="form">
                                <div v-if="message" class="alert" v-bind:class="{
                                    'alert-success': message.type === 'success',
                                    'alert-danger': message.type === 'error'
                                }" role="alert">
                                    {{ message.message }}
                                </div>
                                <input type="text" class="form-control std-input" name="code" placeholder="Enter code"
                                    min="0" aria-label="Code" v-model="code" />
                                <button @click="affiliate" v-bind:class="loading" class="btn-std btn-prim btn-large"
                                    :disabled="loading">
                                    {{ loading ? 'Processing...' : 'JOIN' }}
                                </button>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import affiliate from "@/services/affiliate";


import MenuItem from './MenuItem.vue';

export default {
    name: "SortDeposit",
    data: () => ({
        code: null,
        loading: false,
        message: null,
        myProfile: null,
    }),
    components: {
        MenuItem
    },
    mounted() {

        window.$(this.$refs.redeemModal).on('hidden.bs.modal', this.reset);
        this.myProfile = this.getProfile();
        if (this.myProfile) {
            this.code = this.myProfile.referral_code;
        }
    },

    methods: {
        reset() {
            this.code = null;
            this.message = null;
            this.loading = false;
        },

        affiliate() {
            this.message = null;
            var p = this.getAuth();
            if (!p) {
                this.$toast.open({
                    message: 'Please login to proceed',
                    type: 'error',
                    position: 'top'
                });
                this.$router.push({ name: "login", params: {} });
                return;
            }

            var vm = this;
            var path = "/join";

            var data = {
                code: this.code,
            };

            this.loading = true;

            affiliate
                .post(path, JSON.stringify(data), {
                    headers: {
                        "api-key": vm.getAuth(),
                    },
                })
                .then((res) => {
                    var msg = res.data.data;
                    this.message = {
                        message: msg,
                        type: 'success'
                    }

                    this.$router.push('/affiliates');
                })
                .catch((err) => {
                    if (err.response) {
                        if (
                            parseInt(err.response.data.error_code) === 401 ||
                            parseInt(err.response.data.error_code) === 400 ||
                            parseInt(err.response.data.error_code) === 428
                        ) {
                            this.$toast.open({
                                message: 'Your session on this device has expired',
                                type: 'error',
                                position: 'top'
                            });
                            // vm.logout();
                            return;
                        } else {
                            this.message = {
                                message: err.response.data.error_message ?? 'Failed',
                                type: 'error'
                            }

                        }
                    } else if (err.request) {
                        this.message = {
                            message: 'Network Error. Check your network connection and try again',
                            type: 'error'
                        }


                    } else {
                        this.message = {
                            message: 'An error occurred. Please try again',
                            type: 'error'
                        }
                    }
                })
                .finally(() => {
                    this.loading = false

                })
        },
    }
}
</script>

<style scoped>
.form,
.wallet-modal {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.affiliate-modal {
    background: rgba(0, 0, 0, 0.5);
}

.alert {
    position: relative;
}

.wallet-modal {
    background: var(--primary);
    padding: 1rem;
    border-radius: 0.5rem;
}

.modal-dialog {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

}

.modal-content {
    margin-top: auto;
    background: none;
}

.md-title {
    display: flex;
    flex-direction: column;
}

.md-title h4 {
    margin: 0;
}

.md-title span {
    font-size: 0.8rem;
    color: rgba(0, 0, 0, 0.5)
}
</style>